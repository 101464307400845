import * as React from "react";
import { Helmet } from "react-helmet";

import metaImage from "../images/meta_image.jpg";

export default function SEO({ title }) {
  const metaTitle = "Shackpets: The Ultimate Battle for Cuteness";
  const metaDescription =
    "Swipe your pet to victory! Pit your dog, cat, or iguana against other's pets to see which pet reigns supreme! Available for iOS and Android.";
  const metaSiteUrl = "https://www.shacknews.com";

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-52037-2");
  }, []);

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{title} - The Ultimate Battle for Cuteness</title>
      <meta name="description" content={metaDescription} />
      <meta name="url" content={metaSiteUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={metaSiteUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${metaSiteUrl}${metaImage}`} />
      <meta name="og:url" content={metaSiteUrl} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Shackpets" />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:image" content={`${metaSiteUrl}${metaImage}`} />
      <meta name="og:image:secure_url" content={`${metaSiteUrl}${metaImage}`} />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="630" />

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-84133604-2"></script>

      <script>{`
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('CHV4P7BC77U0O25EUJ20');
        ttq.page();
      }(window, document, 'ttq');
      `}
      </script>

    </Helmet>
  );
}
